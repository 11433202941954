import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BookIcon from '@material-ui/icons/Book';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useAuthDispatch, useAuthState } from '../../../Context';
import { USER_ROLES } from '../../../utils/constants';
import { useLocation } from 'react-router-dom';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import MenuItems from './MenuItems';
import { useHistory } from 'react-router';
import variables from '../../../Sys/variable.scss';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DvrIcon from '@material-ui/icons/Dvr';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 270,
    verticalAlign: 'top',
    background: variables.sidebarColor,
    minHeight: 655
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: '100%',
  },
  drawerPaper: {
    position: 'initial',
    height: '100%',
    border: '0'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    background: variables.sidebarColor
  },
}));

const superAdminMenus = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    active: '/dashboard',
  },
  {
    title: 'Administrators',
    icon: <PersonIcon />,
    active: '/administrators',
  },
  {
    title: 'Customer Onboarding',
    icon: <PersonAddIcon />,
    active: '/flowcustomer',
  },
  {
    title: 'Customers',
    icon: <GroupIcon />,
    active: '/customers',
  },
  {
    title: 'Users',
    icon: <PersonIcon />,
    active: '/users',
  },
  {
    title: 'Courses',
    icon: <BookIcon />,
    active: '/courses',
  },
  {
    title: 'Assessments',
    icon: <AssessmentIcon />,
    active: '/myassessments',
  },
  {
    title: 'Experiments',
    icon: <AssignmentReturnIcon />,
    active: '/experiments',
  },
  {
    title: 'Deployments',
    icon: <BookIcon />,
    active: '/deployments',
  },
  {
    title: 'Reporting',
    icon: <LibraryBooksIcon />,
    active: '/reporting',
    submenus: [
      {
        title: 'Overview',
        icon: <LibraryBooksIcon />,
        active: '/reporting/overview',
      },
      {
        title: 'Learning as a service',
        icon: <LibraryBooksIcon />,
        active: '/reporting/learning-service',
      },
      {
        title: 'Digital Readiness',
        icon: <LibraryBooksIcon />,
        active: '/reporting/digital-readliness',
      },
    ]
  }
];
const customerAdminMenus = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    active: '/dashboard',
  },
  {
    title: 'Users',
    icon: <PersonIcon />,
    active: '/users',
  },
  {
    title: 'Licenses',
    icon: <NoteAddIcon />,
    active: '/licenses',
  },
  {
    title: 'Calendar',
    icon: <EventAvailableIcon />,
    active: '/calendar',
  },
  {
    title: 'Course Requests',
    icon: <BookIcon />,
    active: '/course-requests',
  },
  {
    title: 'Reporting',
    icon: <LibraryBooksIcon />,
    active: '/reporting',
    submenus: [
      {
        title: 'Overview',
        icon: <LibraryBooksIcon />,
        active: '/reporting/overview',
      },
      {
        title: 'Learning as a service',
        icon: <LibraryBooksIcon />,
        active: '/reporting/learning-service',
      },
      {
        title: 'Digital Readiness',
        icon: <LibraryBooksIcon />,
        active: '/reporting/digital-readliness',
      },
    ]
}];
const CSMAdminMenus = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    active: '/dashboard',
  },
  {
    title: 'Users',
    icon: <PersonIcon />,
    active: '/users',
  },
  {
    title: 'Reporting',
    icon: <LibraryBooksIcon />,
    active: '/reporting',
    submenus: [
      {
        title: 'Overview',
        icon: <LibraryBooksIcon />,
        active: '/reporting/overview',
      },
      {
        title: 'Learning as a service',
        icon: <LibraryBooksIcon />,
        active: '/reporting/learning-service',
      },
      {
        title: 'Digital Readiness',
        icon: <LibraryBooksIcon />,
        active: '/reporting/digital-readliness',
      },
    ]
}];
const userAdminMenus = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    active: '/user/dashboard',
  },
  {
    title: 'Assessments',
    icon: <AssessmentIcon />,
    active: '/assessments',
  },
  {
    title: 'Skills Transformation',
    icon: <LibraryBooksIcon />,
    active: '/skill-transformation',
    submenus: [
      {
        title: 'Course Catalogue',
        icon: <MenuBookIcon />,
        active: '/skill-transformation/course-catalogue',
      },
      {
        title: 'Work Readiness',
        icon: <DvrIcon />,
        active: '/skill-transformation/work-readiness',
      },
      {
        title: 'Registered Courses',
        icon: <CollectionsBookmarkIcon />,
        active: '/skill-transformation/registered-courses',
      },
      {
        title: 'Virtual Global Apprenticeship',
        icon: <LibraryBooksIcon />,
        active: '/skill-transformation/virtual-global-apprenticeship',
      }
    ]
  },
  {
    title: 'Experiments and Testing',
    icon: <AssignmentIcon />,
    active: '/experiments-and-testing',
  },
  {
    title: 'Reporting',
    icon: <AssessmentIcon />,
    active: '/reporting',
    submenus: [
      {
        title: 'Overview',
        icon: <EqualizerIcon />,
        active: '/reporting/overview',
      },
      {
        title: 'Learning as a service',
        icon: <EqualizerIcon />,
        active: '/reporting/learning-service',
      },
      {
        title: 'Digital Readiness',
        icon: <EqualizerIcon />,
        active: '/reporting/digital-readliness',
      },
    ]
  },
  {
    title: 'Calendar',
    icon: <EventAvailableIcon />,
    active: '/calendar',
  }
];

function getMenuItemsByRole(role) {
  localStorage.setItem("role", role)
  if (role === USER_ROLES.SUPER_ADMIN) {
    return superAdminMenus;
  } else if (role === USER_ROLES.USER) {
    return userAdminMenus;
  } else if(role === USER_ROLES.CSM_ADMIN){
    return CSMAdminMenus
  }else{
    return customerAdminMenus;
  }
}

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useAuthDispatch();
  const { userDetails } = useAuthState(dispatch);

  const menuItems = getMenuItemsByRole(userDetails?.role || USER_ROLES.USER);

  const handleItemClick = (path) => {
    history.push({ pathname: path });
    // window.location.href = path;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ background: variables.sidebarColor }}>
          <div className={classes.toolbar} />
          <List style={{ background: variables.sidebarColor }}>
            {menuItems.map((item, index) => (
              <MenuItems
                menuItems={item}
                index={index}
                handleItemClick={handleItemClick}
                location={location}
              />
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default Sidebar;