import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router';

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#EC7A12"
    }
}))(LinearProgress);

export const BorderLinearProgressComplete = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#06AD17"
    }
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 135,
        minWidth: 135,
        height: 150,
        marginTop: 15,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",
    },
}));


const DynamicCard = ({ item }) => {

    const classes = useStyles();
    const history = useHistory();

    const calculatePercentage = (val, total) => {
        if (!val) return 0;
        let percentage = (val / total) * 100
        return Math.floor(percentage)
    }

    const handleClickItem = (itm) => {
        if (itm.internalPage) {
            history.push(itm.link);
        } else {
            window.location.href = itm.link;
        }
    }

    let cardData = null;

    if (item.width === 12) {
        cardData = <Grid key={item.title} item xs={12}>
            <Card onClick={() => handleClickItem(item)} style={{ cursor: "pointer" }} className={classes.root}>
                <CardMedia
                    className={classes.cover}
                    image={item.image}
                    title={item.title}
                />
                <CardContent className={classes.content} style={{ display: 'flex' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={9} style={{ height: 180, overflow: 'auto'}}>
                            <Typography className={classes.cardTitle}>
                                {item.title}
                            </Typography>
                            <Typography variant="h1" color="primary">
                                {item.subTitle}
                            </Typography>
                            <Typography className={classes.cardDesc} title={item.desc}>
                                {item.desc}
                            </Typography>
                            <Typography variant="h1" color="primary">
                                {item.totalCount}
                            </Typography>

                            {item.progress ? calculatePercentage(item.value, item.total) !== 100 ? <BorderLinearProgress variant="determinate" value={calculatePercentage(item.value, item.total)} /> :
                                <BorderLinearProgressComplete variant="determinate" value={calculatePercentage(item.value, item.total)} /> : null}

                            <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                                {calculatePercentage(item.value, item.total) + "% " + "completed"}
                            </Typography>
                        </Grid>
                        {item.title !== "Virtual Global Apprenticeship Progress" &&
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <Button 
                            style={{ width: '100%', padding: '15px 45px', borderRadius: 10 }} 
                            variant="outlined" color="primary">
                                {(item.value > '0') ? 'Continue Course' : 'Launch'}
                            </Button>
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    } else {
        cardData = <Grid key={item.title} item xs={item.width}>
            <Card onClick={() => handleClickItem(item)} style={{ cursor: "pointer" }} className={classes.root}>
                <CardMedia
                    className={classes.cover}
                    image={item.image}
                    title={item.title}
                />
                <CardContent className={classes.content}>
                    <Typography className={classes.cardTitle}>
                        {item.title}
                    </Typography>
                    <Typography variant="h1" color="primary">
                        {item.subTitle}
                    </Typography>
                    <Typography className={classes.cardDesc} title={item.desc}>
                        {item.desc.slice(0, 150) + (item.desc.length > 150 ? "..." : "")}
                    </Typography>
                    <Typography variant="h1" color="primary">
                        {item.totalCount}
                    </Typography>
                    <Typography style={{ color: '#716d6d', fontSize: 15, fontWeight: 600 }}>
                        {`${item.value} of ${item.total}`}
                    </Typography>
                    {calculatePercentage(item.value, item.total) !== 100 ? <BorderLinearProgress variant="determinate"
                        style={{ width: '80%' }}
                        value={calculatePercentage(item.value, item.total)}
                    /> : <BorderLinearProgressComplete
                        style={{ width: '80%' }}
                        variant="determinate"
                        value={calculatePercentage(item.value, item.total)}
                    />}

                    <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                        {calculatePercentage(item.value, item.total) + "% " + "completed"}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    }

    return (
        <>
            {cardData}
        </>
    )
}

export default DynamicCard;